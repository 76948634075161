import React, { useRef, useState, Fragment, useContext } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../Search/MobileSearch"
import MedicalSuppliesModal from "../Elements/MedicalSuppliesModal"
import AddMedicineSlidingModal from "./SearchMedsMobile/AddMedicineSlidingModal"
import SpecialRequestModal from "./Order/SpecialRequestModal"
import FloatingActionButton from "../Elements/FloatingActionButton"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { reformatAirtableData } from "./services/reformatAirtableData"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"

const SearchMedsMobile = () => {
  const [selectedMedicine, setSelectedMedicine] = useState(null)
  const [addedMedicine, setAddedMedicine] = useState(null)
  const { state, dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })
  const data = useEpharmacyProducts()

  const handleProceedToUpload = () => {
    navigate("/epharmacy/upload")
  }

  const proceedToCheckout = () => {
    navigate("/epharmacy/checkout")
  }

  const handleContinueSearching = () => {
    navigate("/epharmacy/order")
  }

  const showCart = () => {
    dispatch({
      type: "SHOW_CART",
    })
    setAddedMedicine(null)
  }

  const handlePartnerRequest = (medicine) => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, hasPartnerRequest: true },
      },
    })
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: medicine.productTitle,
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        content: (
          <SpecialRequestModal
            route={medicine.productTitle}
            handleContinueSearching={handleContinueSearching}
            handleProceedToUpload={handleProceedToUpload}
          />
        ),
      },
    })
  }

  const handleMedgrocerWellnessPatient = (medicine) => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, isMedGrocerWellnessPatient: true },
      },
    })
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: medicine.productTitle,
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        content: (
          <SpecialRequestModal
            route={medicine.productTitle}
            handleContinueSearching={handleContinueSearching}
            handleProceedToUpload={handleProceedToUpload}
          />
        ),
      },
    })
  }

  const handleOnSelect = (medicine) => {
    if (
      medicine?.category === "Medical Supply" &&
      state?.epharmacy?.coupon?.websiteDescription?.includes(
        "Free quarterly coupons for MedGrocer.com"
      )
    ) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          isCard: true,
          isBodyOnly: true,
          hideCloseButton: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: <MedicalSuppliesModal dispatch={dispatch} />,
        },
      })
    } else {
      if (medicine.productTitle === "Partner Request (MedGrocer)")
        return handlePartnerRequest(medicine)

      if (medicine.productTitle === "MedGrocer Wellness Patient")
        return handleMedgrocerWellnessPatient(medicine)

      if (isMedicineAlreadyInCart(medicine, state.epharmacy.medicines))
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `${medicine.productTitle} already in cart`,
            color: "danger",
            isMedToast: true,
          },
        })
      else {
        dispatch({
          type: "SHOW_SLIDING_MODAL",
          payload: {
            isCard: true,
            hideCloseButton: false,
            isClosed: false,
            background: {
              opacity: 40,
            },
            content: (
              <AddMedicineSlidingModal
                dispatch={dispatch}
                addMedicineToCart={addMedicineToCart}
                setSelectedMedicine={setSelectedMedicine}
                selectedMedicine={medicine}
                setAddedMedicine={setAddedMedicine}
                proceedToCheckout={proceedToCheckout}
              />
            ),
          },
        })
      }
    }
  }

  let medgrocerProducts = data.allAirtableallMedGrocerProducts.nodes.map(
    (node) => node.data
  )
  medgrocerProducts = medgrocerProducts.map(reformatAirtableData)

  const searchComponentProps = {
    placeholder: "Paracetamol",
    label: "Medicine Search",
    dataSource: {
      type: "json",
      data: medgrocerProducts,
      lookupKeys: ["molecule", "brand", "productTitle"],
    },
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backRoute: "/epharmacy/order",
    addedMedicine: addedMedicine,
    setAddedMedicine: setAddedMedicine,
  }

  const addMedicineToCart = async (item, qty) => {
    const medicineList = [...state.epharmacy.medicines]
    if (!item.molecule) medicineList.push({ ...item, qty: qty })
    else medicineList.push({ ...item, qty: qty })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
      <FloatingActionButton
        icon={faShoppingCart}
        handleOnClick={showCart}
        showBadge={addedMedicine}
      />
    </Fragment>
  )
}

export default SearchMedsMobile

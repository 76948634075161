const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
const SET_SEARCH_QUEUE = "SET_SEARCH_QUEUE"
const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS"
const SET_IS_LOADING = "SET_IS_LOADING"

export let MobileSearchReducer = (state, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload }
    case SET_SEARCH_QUEUE:
      return { ...state, searchQueue: action.payload }
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload }
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}

import React from "react"
import MobileLayout from "../../components/Layout/MobileLayout"
import SearchMedsMobile from "../../components/Epharmacy/SearchMedsMobile"

// export default () => 

export default () => {
  return (
    <MobileLayout
      title="Search your Medicine"
      subtitle="Add Medicines to your cart"
      process="epharmacy"
      seoTitle="Search Medicines"
      display={{ footer: false, helpCenterBanner: false }}
      // path={location?.pathname}
    >
      <SearchMedsMobile />
    </MobileLayout>
  )
}

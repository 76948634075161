import React, { useContext } from "react"
import { Formik, Form } from "formik"

import MedicineInfo from "../Order/MedicineInfo"
import MedicineQuantityInput from "../Order/MedicineQuantityInput"
import TotalPriceInfo from "../Order/TotalPriceInfo"
import { changeQuantity } from "../services/cart"
import { generatePrice } from "../services/computations"
import Message from "../../Elements/Message"

import { AppContext } from "../../../context/AppContext"

const AddMedicineSlidingModal = ({
  setSelectedMedicine,
  addMedicineToCart,
  selectedMedicine,
  setAddedMedicine,
  dispatch,
  proceedToCheckout,
}) => {
  const HIGH_VALUE_MEDS = {
    DEFAULT_QUANTITY: 1,
    MINIMUM_PRICE: 50,
  }
  const DEFAULT_QUANTITY = 10
  let initialQuantity =
    generatePrice(selectedMedicine) > HIGH_VALUE_MEDS.MINIMUM_PRICE
      ? HIGH_VALUE_MEDS.DEFAULT_QUANTITY
      : DEFAULT_QUANTITY

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <Formik
      initialValues={{ qty: initialQuantity }}
      onSubmit={async (values) => {
        dispatch({ type: "CLOSE_SLIDING_MODAL" })
        await delay(300)
        dispatch({ type: "HIDE_SLIDING_MODAL" })
        setSelectedMedicine(null)
        setAddedMedicine(true)
        dispatch({
          type: "REMOVE_ACTIVE_DOCUMENT_ONLY",
        })
        if (addMedicineToCart) addMedicineToCart(selectedMedicine, values.qty)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mx-4 mx-1-mobile">
            <MedicineInfo medicine={selectedMedicine} />
            <MedicineQuantityInput
              changeQuantity={changeQuantity}
              setFieldValue={setFieldValue}
              qty={values.qty}
              initialQuantity={initialQuantity}
              selectedMedicine={selectedMedicine}
            />
            <hr className="has-background-light my-0" />
            <TotalPriceInfo medicine={selectedMedicine} qty={values.qty} />
            <div className="is-flex buttons is-justify-content-space-between">
              <button
                type="submit"
                className="button is-flex-grow-1 is-primary is-medium mt-2"
                disabled={values.qty < 1}
              >
                Add to cart
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddMedicineSlidingModal
